import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import ContactSec from "../components/contact/contact.js"
import Hero from "../components/members-zone/hero-infographics"

const Contact = ({ data }) => (
  <div className="membersZone" infographicsPage>
    <Layout>
      <Seo title="Infographics | Members Zone - COMMB" />
      <Hero />
      <div className="membersZoneCardThumbnailsWrapper infoGraphics">
        <h2>Sales Categories</h2>
        <ul className="salesInfoGraphics">
          <li>
            <a href="https://images.ctfassets.net/1l6si2vnlb2k/1yMQf9NoqKio2rPHp2V9WY/efe348a472619174cb93d1c8d2d6dbdf/Automotive_Infographic_-_EN.png " target="_blank">Automotive</a>
          </li>
        </ul>
      </div>
    </Layout>
  </div>
)

export default Contact